<template>
    <div>
        <Loading :isLoading="isLoading" />
        <div>
            <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Historial de Layouts</p>
        </div>
        <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="false" :exportInput="false" :returnInputChecked="inputCheckedStatus"/>
        <div class="flex w-full mx-auto my-10">
            <div v-if="accountType == 'supervisor'" id="button_reverse_last_layout" class="ml-auto w-56 px-4 py-2 mx-2 border-2 border-mustard bg-white text-center text-mustard rounded cursor-pointer" @click="revertLayout()">
                Revertir último layout
            </div>
            <router-link id="button_generate_new_layout" :to="`/${accountType}/reportes/layouts/socios`" :class="{'mx-auto md:ml-auto md:mr-0': accountType != 'supervisor'}" class=" w-56 px-4 py-2 mx-2 border-2 border-mustard bg-mustard text-center text-white rounded cursor-pointer">
                Generar nuevo layout
            </router-link>
        </div>
    </div>
</template>

      
  
<script>
import AdmissionRequest from "@/classes/AdmissionRequest";
import moment from "moment";

import {
    onBeforeMount,
    getCurrentInstance,
    ref,
    computed
} from 'vue';

import Header from '@/components/Analyst/Header.vue';
import MenuLateral from '@/components/Analyst/MenuLateral.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import '@/assets/css/tailwind.css';
import {
    getTokenDecoden
} from "@/helpers/tokenauth";

import Affiliated from '@/classes/Affiliated.js';

import TableComponent from "@/components/Forms/Tables/TableComponent";

import Swal from "sweetalert2";

import {
    useStore
} from 'vuex';

export default {

    name: 'SolicitudesAceptadas',
    
    setup() {
        const store = useStore();
        const isLoading = ref(false);
        const apiToken = ref("");
        const clientId = ref("");
        const userId = ref(0);
        const name = ref("");
        const paternal_name = ref("");
        const affiliatedObject = ref(null);
        const rowsSelected = ref([]);
        const accountType = ref("");
        
        let app = getCurrentInstance();
        let render = ref(false);
        let columns = ref([]);
        let rows = ref([]);
        let admissionObject = ref(null);

        let layoutId = ref(0);

        const inputCheckedStatus = ref(false);


        onBeforeMount(async () => {
            isLoading.value = true;
            affiliatedObject.value = new Affiliated();
            admissionObject.value = new AdmissionRequest();

            app = app.appContext.config.globalProperties;
            loadInfo();

            await getLayoutClientItbnksHistory();
        });

        const isAvailableToReverse = () => {
            
            let today = moment().format('YYYY-MM-DD');
            let layout = rows.value.find(row => row.layout_id == layoutId.value);
            let layoutDate = layout.created_at.split("/");
            
            layoutDate = moment(`${layoutDate[2]}-${layoutDate[1]}-${layoutDate[0]}`).format('YYYY-MM-DD');
            let status = layoutDate == today;

            return {
                status: status,
                layout: {
                    name: layout.name,
                    layout_id: layout.layout_id,
                    date: layoutDate
                }
            }
        }

        const revertLayout = () => {
            let isAvalaibleToReverse = isAvailableToReverse();
            if(!isAvalaibleToReverse.status){
                Swal.fire({
                    title: "Aviso",
                    html: `No es posible revertir el layout porque la fecha de creación es del ${app.$filters.formatDate(isAvalaibleToReverse.layout.date)}`,
                    icon: "info",
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "NO",
                })
                return;
            }

            Swal.fire({
                title: "Aviso",
                html: `¿Desea revertir el layout <b>${isAvalaibleToReverse.layout.name}</b>?`,
                icon: "question",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#E9AD3F',
                cancelButtonColor: '#E9AD3F',
                confirmButtonText: 'SI',
                cancelButtonText: "NO",
            }).then(async resp => {

                if(resp.isConfirmed){
                    await cancelLayout();
                    await getLayoutClientItbnksHistory();
                }

            });

            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete');

        }

        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }

        const status = computed(() => {
            return store.getters.getForwardingCertificatesStatus;
        });

        const getLayoutClientItbnksHistory = async () => {
            isLoading.value = true;

            await admissionObject.value.getLayoutClientItbnksHistory(apiToken.value).then(resp => {
               if(resp.data.response.result == ""){
                getColumns();
                render.value = !render.value;
                isLoading.value = false;
                return;
               } 
               
                rows.value = getFinalArrayList(resp.data.response.result).reverse();
                getColumns();
                render.value = !render.value;

                layoutId.value = resp.data.response.result.pop().layout_id;
                isLoading.value = false;

            }).catch(err => {

                Swal.fire({
                    icon: "error",
                    title: "Aviso",
                    html: ((err.response.status == 500))?`Ha ocurrido un error al consultar los layouts`: err.response.data.response.message,
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Cancelar"
                });

                isLoading.value = false;

            });
            
            
        }

        const cancelLayout = async() => {

            isLoading.value = true;
            await admissionObject.value.cancelLayout(apiToken.value, layoutId.value).then(resp => {

                Swal.fire({
                    title: "Aviso",
                    html: `${resp.data.response.message}`,
                    icon: "success",
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok'
                });
                
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm', '');
                isLoading.value = false;
            }).catch(err => {
                 
                Swal.fire({
                    icon: "error",
                    title: "Aviso",
                    html: ((err.response.status == 500))?`No es posible revertir el último layout`: err.response.data.response.message,
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Cancelar"
                });

                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm', '');
                isLoading.value = false;
            });
        }

        const getColumns = () => {
            columns.value = [{
                    headerName: "Nombre archivo",
                    field: "name",
                    headerCheckboxSelection: false,
                    minWidth: 370,
                    resizable: true,
                    sortable: true,
                    filter: true,
                    checkboxSelection: false,
                },
                {
                    headerName: "Fecha de generación",
                    field: "created_at"
                },
                {
                    headerName: "Fecha de cancelación",
                    field: "cancellation_date"
                },
                {
                    headerName: "Cancelado por",
                    field: "canceling_user"
                },
                {
                    headerName: "No. socios",
                    field: "number_of_members"
                },
                {
                    headerName: "Usuario que genero layout",
                    field: "user_generate"
                },
                {
                    headerName: "Estatus",
                    field: "active"
                },
                {
                    headerName: "",
                    field: "file_name",
                    cellRenderer: params => {
                        console.log("paramas: ", params);
                        if(params.value){
                            const link = document.createElement("a");
                            link.innerHTML = `&#x2B07;`;
                            link.className += "cursor-pointer border rounded px-2 py-1 bg-mustard border-mustard text-white mx-10";
                            link.addEventListener("click", e => {
                                e.preventDefault();
                                downloadLayout(params.value);
                            });
                            
                            return link;
                        }

                    }
                }
            ];
        }

        const downloadLayout = async(documentName) => {
            await admissionObject.value.getUrlToDownloadLayout(apiToken.value, documentName).then(resp => {

                let url = resp.data.response.result;
                if(url == ""){
                    Swal.fire({
                        icon: "error",
                        title: "Aviso",
                        html: resp.data.response.message,
                        showCancelButton: false,
                        reverseButtons: true,
                        confirmButtonColor: '#E9AD3F',
                        cancelButtonColor: '#E9AD3F',
                        confirmButtonText: 'Ok',
                        cancelButtonText: "Cancelar"
                    });

                    return;
                }
                const splitName = documentName.split("_");
                const documentJoin = splitName[0] + '_' + splitName[1];

                window.open(resp.data.response.result[documentJoin], '_blank');
            }).catch(err => {
                console.log("Error:", err.data.response.message);
                Swal.fire({
                    icon: "error",
                    title: "Aviso",
                    html: ((err.response.status == 500))?`No es posible revertir el último layout`: err.data.response.message,
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Cancelar"
                });
            });
            
        }


        const loadInfo = () => {
            var auth = getTokenDecoden();

            accountType.value = auth.obj['role'];
            apiToken.value = sessionStorage.getItem("login");
            userId.value = auth.obj['id'];
            name.value = auth.obj['name'];
            paternal_name.value = auth.obj['paternal_name'];
        }

        const getFinalArrayList = (array) => {
            let finalArray = [];

            for (let i = 0; i < array.length; i++) {
                finalArray.push({
                    abbreviation: array[i].abbreviation,
                    active: array[i].active,
                    cloud_key: array[i].cloud_key,
                    created_at: app.$filters.formatDate(array[i].generation_date),
                    extension: array[i].extension,
                    id: array[i].id,
                    name: app.$filters.formatCamelize(`${array[i].file_name}`),
                    table_id: array[i].table_id,
                    table_type: array[i].table_type,
                    layout_id: array[i].layout_id,
                    updated_at: app.$filters.formatDate(array[i].updated_at),
                    file_name: array[i].file_name,
                    user_generate: app.$filters.formatCamelize(array[i].user_generate),
                    number_of_members: array[i].number_of_members,
                    active: (array[i].active)? 'ACTIVO': 'INACTIVO',
                    cancellation_date: app.$filters.formatDate(array[i].cancellation_date),
                    canceling_user: app.$filters.formatCamelize(array[i].canceling_user)
                });
            }

            return finalArray;
        }

        return {
            render,
            columns,
            rows,
            status,
            revertLayout,
            rowsSelected,
            inputCheckedStatus,
            accountType,
            isLoading
        }
    },
    components: {
        Header,
        Loading,
        MenuLateral,
        TableComponent
    }
}
</script>
      
  
<style scoped>
.main-layout {
    background: red;
}

.new-request {
    background: red;
}

table,
th,
td {
    border-collapse: collapse;
}

th,
td {
    padding: 15px 10px 15px 10px !important;
}

table,
th,
td {
    border-bottom: 1px solid #85C875;
}

table thead tr th {
    border-bottom: 1px solid #85C875 !important;
}

tr {
    color: #174A84;
    font-family: lato;
    font-size: 12px;
}

#menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 200px;
    font-family: Arial, sans-serif;
    font-size: 11pt;
}

#menu ul {
    width: 100%;
}

#menu ul li {
    font-family: "Roboto";
    border-bottom: 1px solid #85C875;
    color: #174A84;
    display: block;
    padding-bottom: 0;
    padding-top: 10px;
    padding-left: 30px;
}

.buttonAdd {
    background-color: #4CAF50;
    /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-family: Lato;
    widows: 10px;
    cursor: pointer;
    background-color: #174A84;
}

/* Sort Table */
.arrow_down {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}

.arrow_up {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}

.arrow {
    float: right;
    width: 12px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
}

th.active {
    color: #000;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0px;
    margin-top: 7px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #174A84;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #174A84;
}

.arrow-right {
    background: #000;
    height: 3px;
    width: 30px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.pagination {
    justify-content: center !important;
}

.number {
    display: inline-block;
    padding: 4px 10px;
    color: #FFF;
    border-radius: 4px;
    background: #44475C;
    margin: 0px 5px;
    cursor: pointer;
}

.number:hover,
.number.active {
    background: #717699;
}

select {
    border: 1px solid #174A84;
    border-radius: 5px;
    padding: 3px;
}

@media only screen and (max-width: 1024px) {

    table.width200,
    .width200 thead,
    .width200 tbody,
    .width200 th,
    .width200 td,
    .width200 tr {
        display: block;
    }

    .width200 thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .width200 tr {
        border: 1px solid #4CAF50;
    }

    .width200 td {
        border: none;
        border-bottom: 1px solid #4CAF50;
        position: relative;
        padding-left: 40%;
        text-align: left
    }

    .width200 td:before {
        top: 6px;
        width: 45%;
        white-space: nowrap;
    }

    .width200 td:nth-of-type(1):before {
        content: "R.F.C.:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(2):before {
        content: "Nombre:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(3):before {
        content: "Teléfono:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(4):before {
        content: "Correo:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(5):before {
        content: "Monto a Ahorrar:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(6):before {
        content: "Puesto/Ocupación:";
        color: #174A84;
        font-weight: bold;
    }

    .width200 td:nth-of-type(7):before {
        content: "Fecha de Registro:";
        color: #174A84;
        font-weight: bold;
    }

    .descarto {
        display: none;
    }

    .fontsize {
        font-size: 10px
    }
}

.buttonShow {
    background-color: #4CAF50;
    /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-family: Lato;
    widows: 10px;
    cursor: pointer;
    background-color: #174A84;
}

button.swal2-styled {
    width: 45%;
}
</style>
