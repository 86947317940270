import api from '@/api/api';

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA;
const hostMultiApi = process.env.VUE_APP_MULTIAPI;
export default class Affiliated {

    constructor() {
    }

    getAffiliatedBusiness = async (token) => {
        const resource = `/${business}/get_list_affiliated_business?token_auth=${token}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { withCredentials: true, token_auth: token };

        try {
            return await api.httpGet(API_URL, params, config);
        } catch (error) {
            throw error.response;
        }
    }

    getOccupationByClientType = async (token, type = "cabsa_employee") => {
        const resource = `/${business}/get_catalogs_occupation_by_client_type?token_auth=${token}&client_type=${type}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { withCredentials: true, token_auth: token };

        return await api.httpGet(API_URL, { params, config }).catch(err => { return err });
    }

    getColonies = async (postalCode) => {

        const resource = `/Api/Catalogo/Sepomex/obtener_colonias`;
        const API_URL = `${hostMultiApi}${resource}`;

        const config = {
            contenido: {
                codigo_postal: postalCode,
                colonia: ""
            },
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        };

        const auth = {
            auth: {
                username: "multicr",
                password: "*cabsa2018*"
            }
        };

        return await api.httpPost(API_URL, auth, config).catch(err => { return err });
    }

    getUnionSections = async (token) => {

        const resource = `/${business}/union_sections/index?token_auth=${token}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { withCredentials: true, token_auth: token };

        try {
            return await api.httpGet(API_URL, params, config);
        } catch (error) {
            throw error.response;
        }
    }

    getBusinessActivities = async (token) => {
        const apiUrl = `${host}/${business}/business_activities/index?token_auth=${token}`
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const params = { withCredentials: true, token_auth: token };
        try {
            return await api.httpGet(apiUrl, params, config);
        } catch (error) {
            throw error.response;
        }
    }

    getAllAnalysts = async (token) => {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/associate/get_all_analysts?token_auth=${token}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const params = { withCredentials: true, token_auth: token };
        
        return await api.httpGet(apiUrl, params, config).catch(err => err);
    }

    setAnalysts = async (token, new_fonsnte_user_id, person_id, modified_by, admission_request_id) => {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/asign_analyst?token_auth=${token}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            new_fonsnte_user_id: new_fonsnte_user_id,
            person_id: person_id,
            modified_by: modified_by,
            admission_request_id: admission_request_id
        };
        return await api.httpPost(apiUrl, config, params).catch(err => err);
    }

    getNewRequests = async (token, status) => {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_admission_request_list_by_status?token_auth=${token}&status=${status}`;
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        const params = { withCredentials: true, token_auth: token, status: status };
        return await api.httpGet(apiUrl, params, config).catch(err => err);
    }
    
    getPendingRequests = async (token, person_id) => {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_all_admission_requests_by_associate?token_auth=${token}`;
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        const params = { withCredentials: true, token_auth: token, person_id: person_id };
        return await api.httpGet(apiUrl, config, params).catch(err => err);
    }

    getDocumentCincel = async (token, type, id_admission_request, abbreviation, process_type) => {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/cincel/get_document`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            withCredentials: true, 
            token_auth: token,
            cincel_request_table_type: type,
            cincel_request_table_id: id_admission_request,
            abbreviation: abbreviation,
            process_type: process_type
        };
        
        return await api.httpPost(apiUrl, config, params).catch(err => err);
    }
}